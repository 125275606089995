import { Button, Form, Input } from "antd";
import React, { useEffect } from "react";
import { UserEmail } from "../../../../services/SendEmailService";
import { motion } from "framer-motion";
import { useRouter } from "../../../../hooks/useRouter/useRouter";
import { NavButtonsComponent } from "../../Common/NavButtonsComponent";

export interface EnterEmailComponentProps {
  onSubmit: (value: UserEmail) => void;
  value: UserEmail;
}

export const EnterEmailComponent = (
  {
    onSubmit,
    value,
  }: EnterEmailComponentProps) => {
  const goToNextPage = () => {
    navigate('/sent')
  }
  const goToPrePage = () => {
    navigate('/carousel');
  }

  const { query, location, navigate } = useRouter();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [query, location])

  return (
    <motion.div className="send-email-component"
                onPanEnd={(e, pointInfo) => {
                  if (Math.abs(pointInfo.delta.x) < Math.abs(pointInfo.delta.y) && pointInfo.delta.y > 5) {
                    goToPrePage();
                  }
                }}
    >
      <Form id='form_send_email'
            className="bottom_container"
            onFinish={onSubmit}
            initialValues={value}
      >
        <div className="mt20 mb20">
          <img
            alt=""
            width={200}
            src={require('../../../../public/magazine/Diwali-cover.jpg')}
          />
        </div>
        <div>
              <span className="text_sent">
                Enter your email address below:
              </span>
        </div>
        <div className="mt30">
          <Form.Item
            label=""
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input
              type="email"
              placeholder={'Enter email address'}
            />
          </Form.Item>
        </div>
        <Button className="cta_container mt10"
                htmlType="submit"
        >
      <span className="cta_text">
        Send Email
      </span>
        </Button>
      </Form>
      <NavButtonsComponent
        onPre={goToPrePage}
        showPre={true}
        showNext={false}
      />
    </motion.div>
  )
}
