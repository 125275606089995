import React, { useEffect, useState } from "react";
import { Progress } from "antd";
import './LoadingComponent.scss';
import { statisticsService } from "../../services/StatisticsService";
import { EVENT_SHOWED_LOADING_PAGE } from "../../features/storyIntroduce/Common/Consts";
import { useRouter } from "../../hooks/useRouter/useRouter";

export interface LoadingComponentProps {
  onFinish?: () => void;
}
export const LoadingComponent = (
  {
    onFinish
  }: LoadingComponentProps) => {
  const [progress, setProgress] = useState<number>(10);

  let temp = 10;
  let timer: NodeJS.Timeout;
  const updateProgress = () => {
    console.log('progress-->', progress)
    temp = temp + parseInt((5*Math.random()).toFixed(0));
    if (temp < 100) {
      setProgress(temp);
      timer = setTimeout(()=> {
        updateProgress();
      }, 500);
    }
  }
  const { navigate } = useRouter();
  useEffect(() => {
    const images = document.querySelectorAll('img');
    const imageLoadStatus = Array(images.length).fill(false);
    const checkImagesLoaded = () => {
      const allLoaded = imageLoadStatus.every(loaded => loaded);
      if (allLoaded) {
        console.log('All images have been loaded.');
        onFinish?.();
        navigate('intro');
      }
    }
    images.forEach((image, index) => {
      if (image.complete) {
        imageLoadStatus[index] = true;
        checkImagesLoaded();
      } else {
        image.addEventListener('load', () => {
          imageLoadStatus[index] = true;
          checkImagesLoaded();
        });
        image.addEventListener('error', () => {
          console.error(`Image ${index + 1} failed to load.`);
          // Set imageLoadStatus[index] to true to skip failed images
          imageLoadStatus[index] = true;
          checkImagesLoaded();
        });
      }
    });
    statisticsService.postEvents(EVENT_SHOWED_LOADING_PAGE);
    timer = setTimeout(()=> {
      updateProgress()
    }, 500);
    return () => {
      clearTimeout(timer);
    }
  }, []);

  return (
    <div className="LoadingComponent">
      <div>
        <div className="text">
          Loading...
        </div>
        <Progress
          percent={progress}
          status="active"
          strokeWidth={16}
        />
        <div className="text">
          {`${progress}%`}
        </div>
      </div>
      <div className="loading_images">
        <img src={require('../../public/raw/recipe_chana_masala_detail.png')} alt="" width={1} />
        <img src={require('../../public/raw/recipe_dry_fruit_ladoo_detail.png')} alt="" width={1} />
        <img src={require('../../public/raw/recipe_masala_chai_detail.png')} alt="" width={1} />
        <img src={require('../../public/arrow_left.png')} alt="" width={1} />
        <img src={require('../../public/arrow_right.png')} alt="" width={1} />
        <img src={require('../../public/diya_on.png')} alt="" width={1} />
        <img src={require('../../public/diya_on_bg.gif')} alt="" width={1} />
        <img src={require('../../public/diya_off_bg.gif')} alt="" width={1} />
        <img src={require('../../public/icon_next.png')} alt="" width={1} />
        <img src={require('../../public/icon_pre.png')} alt="" width={1} />
        <img src={require('../../public/magazine/Diwali-cover.jpg')} alt="" width={1} />
        <img src={require('../../public/magazine/Diwali-3.jpg')} alt="" width={1} />
        <img src={require('../../public/magazine/Diwali-4.jpg')} alt="" width={1} />
        <img src={require('../../public/magazine/Diwali-5.jpg')} alt="" width={1} />
        <img src={require('../../public/magazine/Diwali-6.jpg')} alt="" width={1} />
        <img src={require('../../public/magazine/Diwali-backcover.jpg')} alt="" width={1} />
        <img src={require('../../public/page1_bg.png')} alt="" width={1} />
        <img src={require('../../public/page1_gesture.gif')} alt="" width={1} />
        <img src={require('../../public/page2_bg.png')} alt="" width={1} />
        <img src={require('../../public/page2_lights.gif')} alt="" width={1} />
        <img src={require('../../public/page3_bg.png')} alt="" width={1} />
        <img src={require('../../public/page4_bg.jpg')} alt="" width={1} />
        <img src={require('../../public/recipe_bg.png')} alt="" width={1}/>
        <img src={require('../../public/recipe_chana_masala.png')} alt="" width={1} />
        <img src={require('../../public/recipe_dry_fruit_ladoo.png')} alt="" width={1} />
        <img src={require('../../public/recipe_masala_chai.png')} alt="" width={1} />
        <img src={require('../../public/page2_bg2.png')} alt="" width={1} />
        <img src={require('../../public/page2_bg3.png')} alt="" width={1} />
        <img src={require('../../public/page3_bg2.png')} alt="" width={1} />
        <img src={require('../../public/page3_bg3.png')} alt="" width={1} />
        <img src={require('../../public/swip_left_right.gif')} alt="" width={1} />
      </div>
    </div>
  )
}
