import './App.scss';
import React, { useState } from "react";
import { LoadingComponent } from "./util/LoadingComponent/LoadingComponent";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Intro7SendEmailComponent } from "./features/storyIntroduce/Intro7SendEmail/Intro7SendEmailComponent";
import { CarouselComponent } from "./features/storyIntroduce/Intro7SendEmail/CarouselComponent/CarouselComponent";
import { EmailSentComponent } from "./features/storyIntroduce/Intro7SendEmail/EmailSentComponent/EmailSentComponent";
import { Intro6RecipeComponent } from "./features/storyIntroduce/Intro6Recipe/Intro6RecipeComponent";
import { NoPageComponent } from "./util/NoPageComponent/NoPageComponent";
import { ScrollSnapComponent } from "./features/storyIntroduce/ScrollSnapComponent/ScrollSnapComponent";
import { checkOS } from "./util/MonitorSoftKeyboard";
import { ScrollSnapComponentFull } from "./features/storyIntroduce/ScrollSnapComponent/ScrollSnapComponentFull";

function App() {
  const [ name, setName] = useState('');
  const [ email, setEmail] = useState('');

  if (checkOS() !== 'Android') {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/'>
            <Route index element={<LoadingComponent />} />
            <Route path="intro" element={<ScrollSnapComponentFull />} />
            <Route path="*" element={<NoPageComponent />} />
          </Route>
        </Routes>
      </BrowserRouter>
    )
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/'>
          <Route index element={(
            <LoadingComponent />
          )}/>
          <Route path="intro" element={<ScrollSnapComponent />} />
          <Route path="recipe" element={<Intro6RecipeComponent />} />

          <Route path="name" element={(
            <Intro7SendEmailComponent
              initStatus='name'
              name={name}
              updateName={value => setName(value)}
            />
          )}
          />
          <Route path="carousel" element={<CarouselComponent name={name} />} />
          <Route path="email" element={(
            <Intro7SendEmailComponent
              initStatus='email'
              name={name}
              email={email}
              updateEmail={value => setEmail(value)}
            />)}
          />
          <Route path="sent" element={<EmailSentComponent email={email} />} />
          <Route path="*" element={<NoPageComponent />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
