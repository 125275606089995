// Import the functions you need from the SDKs you need
// https://firebase.google.com/docs/web/setup#available-libraries
import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  setAnalyticsCollectionEnabled,
  logEvent,
} from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { UTM_URL } from "../features/storyIntroduce/Common/Consts";
// @ts-ignore
import mixpanel from "mixpanel-browser";
import queryString from "query-string";
// @ts-ignore
import _ from "lodash";

const apiKey = process.env.REACT_APP_Firebase_apiKey;
const authDomain =  process.env.REACT_APP_Firebase_authDomain;
const projectId= process.env.REACT_APP_Firebase_projectId;
const storageBucket= process.env.REACT_APP_Firebase_storageBucket;
const messagingSenderId= process.env.REACT_APP_Firebase_messagingSenderId;
const appId= process.env.REACT_APP_Firebase_appId;
const measurementId= process.env.REACT_APP_Firebase_measurementId;
const mixpanelToken= process.env.REACT_APP_MIXPANEL_TOKEN;

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export class StatisticsService {
  constructor() {
    setAnalyticsCollectionEnabled(analytics, true);
    const perf = getPerformance(app);
    mixpanel.init(mixpanelToken);
    const query = queryString.parse(window.location.search);
    const userId = _.get(query, 'utm_id', 'unknown_id');
    mixpanel.identify(userId);
    mixpanel.people?.set(query);
    mixpanel.register(query);
  }

  reportWebVitals = (params?: {[key: string]: any;}) => {
    // console.log('reportWebVitals',params)
    logEvent(analytics, 'WebVitals', params);
  }

  postEvents = (eventName: string, params?: {[key: string]: any;}) => {
    // console.log('eventName', eventName, 'params',params)
    logEvent(analytics, eventName, {
      ...params,
      [UTM_URL]: window.location.href
    });
    mixpanel.track(eventName);
  }
}

export const statisticsService = new StatisticsService();
