import React, { useEffect, useRef, useState } from "react";
import { message, Spin } from "antd";
import './Intro7SendEmailComponent.scss';
import { useInView } from "framer-motion";
import { page_margin } from "../Common/Common";
import { statisticsService } from "../../../services/StatisticsService";
import {
  CLICK_GENERATE_RECIPE_BOOK_ON_EMAIL_PAGE,
  CLICK_SEND_TO_MY_EMAIL_ON_EMAIL_PAGE,
  CLICK_SEND_EMAIL_ON_EMAIL_PAGE,
  CLICK_RESEND_EMAIL_ON_EMAIL_PAGE,
  EVENT_EMAIL_SEND_FAILED,
  EVENT_EMAIL_SEND_SUCCESS,
  EVENT_EMAIL_PAGE_ENTERED,
  EVENT_EMAIL_PAGE_LEAVED,
  EVENT_NAME_PAGE_ENTERED,
  EVENT_NAME_PAGE_LEAVED,
} from "../Common/Consts";
import { sendEmailService, UserEmail } from "../../../services/SendEmailService";
import { EnterNameComponent } from "./EnterNameComponent/EnterNameComponent";
import { TransitionComponent } from "./TransitionComponent/TransitionComponent";
import { EnterEmailComponent } from "./EnterEmailComponent/EnterEmailComponent";
import { useRouter } from "../../../hooks/useRouter/useRouter";

export type STATUS = 'name' | 'transition' | 'carousel' | 'email' | 'sent';

export interface Intro7SendEmailComponentProps {
  initStatus: STATUS;
  name?: string;
  updateName?: (value: string) => void;
  email?: string;
  updateEmail?: (value: string) => void;
}
export const Intro7SendEmailComponent = (
  {
    initStatus,
    name = '',
    updateName,
    email = '',
    updateEmail,
  }: Intro7SendEmailComponentProps) => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef(null)
  const isInView = useInView(ref, {
    margin: page_margin
  })
  useEffect(() => {
    if (isInView) {
      setMounted(true);
      if (initStatus === 'name') {
        statisticsService.postEvents(EVENT_NAME_PAGE_ENTERED);
      }
      if (initStatus === 'email') {
        statisticsService.postEvents(EVENT_EMAIL_PAGE_ENTERED);
      }

    } else {
      if (mounted) {
        if (initStatus === 'name') {
          statisticsService.postEvents(EVENT_NAME_PAGE_LEAVED);
        }
        if (initStatus === 'email') {
          statisticsService.postEvents(EVENT_EMAIL_PAGE_LEAVED);
        }
      }
    }
  }, [isInView])

  const [ status, setStatus] = useState<STATUS>(initStatus);

  const [ valueName, setName] = useState<UserEmail>({name, email});
  const [ valueEmail, setEmail] = useState<UserEmail>({name, email});
  const [loading, setLoading] = useState(false);
  const TRANSITION_DURATION = 1000;
  const { navigate } = useRouter();
  const onGenerateBookClicked = (values: UserEmail) => {
    setName(values);
    updateName?.(values.name);
    statisticsService.postEvents(CLICK_GENERATE_RECIPE_BOOK_ON_EMAIL_PAGE);
    setStatus('transition');
    setTimeout(()=> {
      navigate('/carousel');
    }, TRANSITION_DURATION)
  };


  const onSendEmailClicked = (values: UserEmail) => {
    setLoading(true);
    setEmail(values);
    updateEmail?.(values.email);
    statisticsService.postEvents(CLICK_SEND_EMAIL_ON_EMAIL_PAGE);
    sendEmailService.requestSendEmail({
      name,
      email: values.email,
    }).then(res => {
      setLoading(false);
      if (res.status === 200) {
        statisticsService.postEvents(EVENT_EMAIL_SEND_SUCCESS)
        navigate('/sent');
      } else {
        statisticsService.postEvents(EVENT_EMAIL_SEND_FAILED, {
          reason: res
        })
        message.error('Failed to send email!')
      }
      console.log('requestSendEmail res ', res)
    }).catch(reason => {
      setLoading(false);
      statisticsService.postEvents(EVENT_EMAIL_SEND_FAILED, {
        reason
      })
      message.error('Failed to send email!')
      console.log('requestSendEmail reason ', reason)
    })
  };


  const renderActions = () => {
    switch (status) {
      case 'name':
        return (
          <EnterNameComponent
            onSubmit={onGenerateBookClicked}
            value={valueName}
          />
        )

      case 'transition':
        return (
          <TransitionComponent />
        )

      case 'email':
        return (
          <EnterEmailComponent
            value={valueEmail}
            onSubmit={onSendEmailClicked}
          />
        )
    }
    return <div/>
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div id="page_6"  className="pageContainer" ref={ref}>
      <Spin spinning={loading}>
        {renderActions()}
      </Spin>
    </div>
  )
}
