const EVENT_PAGE1_ENTERED: string = 'EVENT_PAGE1_ENTERED';
const EVENT_PAGE1_LEAVED: string = 'EVENT_PAGE1_LEAVED';
const EVENT_PAGE2_ENTERED: string = 'EVENT_PAGE2_ENTERED';
const EVENT_PAGE2_LEAVED: string = 'EVENT_PAGE2_LEAVED';
const EVENT_PAGE3_ENTERED: string = 'EVENT_PAGE3_ENTERED';
const EVENT_PAGE3_LEAVED: string = 'EVENT_PAGE3_LEAVED';
const EVENT_PAGE4_ENTERED: string = 'EVENT_PAGE4_ENTERED';
const EVENT_PAGE4_LEAVED: string = 'EVENT_PAGE4_LEAVED';

const EVENT_404_PAGE_ENTERED: string = 'EVENT_404_PAGE_ENTERED';
const EVENT_404_PAGE_LEAVED: string = 'EVENT_404_PAGE_LEAVED';

const EVENT_DIYA_PAGE_ENTERED: string = 'EVENT_DIYA_PAGE_ENTERED'; // Diya
const EVENT_DIYA_PAGELEAVED: string = 'EVENT_DIYA_PAGELEAVED';

const EVENT_RECIPE_PAGE_ENTERED: string = 'EVENT_RECIPE_PAGE_ENTERED'; // Recipe
const EVENT_RECIPE_PAGE_LEAVED: string = 'EVENT_RECIPE_PAGE_LEAVED';

const EVENT_NAME_PAGE_ENTERED: string = 'EVENT_NAME_PAGE_ENTERED'; // Name
const EVENT_NAME_PAGE_LEAVED: string = 'EVENT_NAME_PAGE_LEAVED';

const EVENT_EMAIL_PAGE_ENTERED: string = 'EVENT_EMAIL_PAGE_ENTERED'; // Email
const EVENT_EMAIL_PAGE_LEAVED: string = 'EVENT_EMAIL_PAGE_LEAVED';

const EVENT_RECIPE_DETAIL_PAGE_ENTERED: string = 'EVENT_RECIPE_DETAIL_PAGE_ENTERED';
const EVENT_RECIPE_DETAIL_PAGE_LEAVED: string = 'EVENT_RECIPE_DETAIL_PAGE_LEAVED';
const RECIPE_NAME: string = 'RECIPE_NAME';

const CLICK_DIYA_ON_DIYA_PAGE: string = 'CLICK_DIYA_ON_DIYA_PAGE';
const CLICK_RECIPE_ITEM_ON_RECIPE_PAGE: string = 'CLICK_RECIPE_ITEM_ON_RECIPE_PAGE';
const CLICK_LEFT_ARROW_ON_RECIPE_PAGE: string = 'CLICK_LEFT_ARROW_ON_RECIPE_PAGE';
const CLICK_RIGHT_ARROW_ON_RECIPE_PAGE: string = 'CLICK_RIGHT_ARROW_ON_RECIPE_PAGE';
const CLICK_SEE_RECIPE_ON_RECIPE_PAGE: string = 'CLICK_SEE_RECIPE_ON_RECIPE_PAGE';
const CLICK_SAVE_ON_RECIPE_DETAIL_PAGE: string = 'CLICK_SAVE_ON_RECIPE_DETAIL_PAGE';
const CLICK_CLOSE_ON_RECIPE_DETAIL_PAGE: string = 'CLICK_CLOSE_ON_RECIPE_DETAIL_PAGE';
const CLICK_GENERATE_RECIPE_BOOK_ON_EMAIL_PAGE: string = 'CLICK_GENERATE_RECIPE_BOOK_ON_EMAIL_PAGE';
const CLICK_SEND_TO_MY_EMAIL_ON_EMAIL_PAGE: string = 'CLICK_SEND_TO_MY_EMAIL_ON_EMAIL_PAGE';
const CLICK_SEND_EMAIL_ON_EMAIL_PAGE: string = 'CLICK_SEND_EMAIL_ON_EMAIL_PAGE';
const CLICK_RESEND_EMAIL_ON_EMAIL_PAGE: string = 'CLICK_RESEND_EMAIL_ON_EMAIL_PAGE';

const EVENT_EMAIL_SEND_SUCCESS: string = 'EVENT_EMAIL_SEND_SUCCESS';
const EVENT_EMAIL_SEND_FAILED: string = 'EVENT_EMAIL_SEND_FAILED';
const UTM_URL: string = 'UTM_URL';

const EVENT_SHOWED_LOADING_PAGE: string = 'EVENT_SHOWED_LOADING_PAGE';

export {
  EVENT_PAGE1_ENTERED,
  EVENT_PAGE1_LEAVED,
  EVENT_PAGE2_ENTERED,
  EVENT_PAGE2_LEAVED,
  EVENT_PAGE3_ENTERED,
  EVENT_PAGE3_LEAVED,
  EVENT_PAGE4_ENTERED,
  EVENT_PAGE4_LEAVED,
  EVENT_404_PAGE_ENTERED,
  EVENT_404_PAGE_LEAVED,
  EVENT_DIYA_PAGE_ENTERED,
  EVENT_DIYA_PAGELEAVED,

  EVENT_RECIPE_PAGE_ENTERED,
  EVENT_RECIPE_PAGE_LEAVED,
  EVENT_NAME_PAGE_ENTERED,
  EVENT_NAME_PAGE_LEAVED,

  EVENT_EMAIL_PAGE_ENTERED,
  EVENT_EMAIL_PAGE_LEAVED,
  EVENT_RECIPE_DETAIL_PAGE_ENTERED,
  EVENT_RECIPE_DETAIL_PAGE_LEAVED,
  RECIPE_NAME,
  CLICK_DIYA_ON_DIYA_PAGE,
  CLICK_RECIPE_ITEM_ON_RECIPE_PAGE,
  CLICK_LEFT_ARROW_ON_RECIPE_PAGE,
  CLICK_RIGHT_ARROW_ON_RECIPE_PAGE,
  CLICK_SEE_RECIPE_ON_RECIPE_PAGE,
  CLICK_SAVE_ON_RECIPE_DETAIL_PAGE,
  CLICK_CLOSE_ON_RECIPE_DETAIL_PAGE,
  CLICK_GENERATE_RECIPE_BOOK_ON_EMAIL_PAGE,
  CLICK_SEND_TO_MY_EMAIL_ON_EMAIL_PAGE,
  CLICK_SEND_EMAIL_ON_EMAIL_PAGE,
  CLICK_RESEND_EMAIL_ON_EMAIL_PAGE,
  EVENT_EMAIL_SEND_SUCCESS,
  EVENT_EMAIL_SEND_FAILED,
  UTM_URL,
  EVENT_SHOWED_LOADING_PAGE,
}
