import React, { useEffect, useState } from "react";
import { useDebounce } from './useDebounce';

export const RotatingComponent = ({
  radius = 200,
  list = Array(4).fill(1).map((value, idx) => ({ value: `${value}-${idx}` })),
  total = list.length,
  renderItem = (item) => (
    <div className="item">
      <span>box{item.value}</span>
    </div>
  ),
  width, // item width,
  height, // item height,
  transitionTime = 500,
  clockwise = true,
  afterOnClick = (item) => {},
  perspective = 900,
  perspectiveOrigin = 'center 500px',
  onClickLeftArrow = () => {},
  onClickRightArrow = () => {}
}) => {
  const [styles, setStyles] = useState([]);
  const [rotatingItems, setRotatingItems] = useState([]);

  const [steps, setSteps] = useState({
    count: 0,
    clockwise,
  });

  const thetaForEach = 360 / (total - 0.15);

  const getXY = (theta) => {
    theta = (theta - 90) * Math.PI / 180;

    return {
      x: radius * Math.cos(theta),
      y: -radius * Math.sin(theta),
    };
  }

  const getStyles = (value) => {
    const itemIdx = rotatingItems.findIndex((item) => item.value === value);
    return styles[itemIdx];
  };

  const shiftArray = (array, clockwise) => {
    const shiftedArray = [...array];
    if (clockwise) {
      // remove first then push to last
      const firstEl = shiftedArray.shift();
      shiftedArray.push(firstEl);
    } else {
      // remove last then push to first
      const lastEl = shiftedArray.pop();
      shiftedArray.unshift(lastEl);
    }
    return shiftedArray;
  };

  useEffect(() => {
    const newRotatingItems = [];
    list.forEach((__, idx, array) => {
      const curItem = array[idx];
      newRotatingItems.push(curItem);
    });
    setRotatingItems(newRotatingItems)

    const newStyles = [];
    list.forEach((__, idx) => {
      let { x, y } = getXY(idx * thetaForEach);
      const xPx = `${x - (width || 0) / 2 }px`;
      const yPx = `${y - (height || 0) / 2}px`;
      const zPx = yPx;

      newStyles.push({
        transform: `translate3d(${xPx}, ${yPx}, ${zPx})`,
      });
    });
    setStyles(newStyles);
    // afterOnClick?.(list[0]);
  }, []);

  const debouncedRotate = useDebounce((
    rotatingItems,
    steps,
  ) => {
    // handle rotating items and styles
    // goal is to move selected item to 0
    console.log('steps::', steps)
    if (steps.count > 0) {
      const shiftedRotatingItems = shiftArray(rotatingItems, steps.clockwise);
      setSteps({
        count: steps.count-1,
        clockwise: steps.clockwise,
      })
      setRotatingItems(shiftedRotatingItems);
    }
  }, { delay: transitionTime, deps: [shiftArray] });

  useEffect(() => {
    debouncedRotate(rotatingItems, steps);
  }, [debouncedRotate, steps, rotatingItems]);

  const renderItems = () => {
    return list.map((__, idx, array) => {
      console.log('idx: ', idx, )
      const curItem = array[idx];
      return (
        <div
          key={idx}
          className="box"
          style={getStyles(curItem.value)}
          onClick={() => {
            const rootGoalIdx = rotatingItems.findIndex((item) => item.value === curItem.value);
            if (rootGoalIdx > 0) {
              const clockwise = rootGoalIdx < rotatingItems.length - rootGoalIdx;
              setSteps({
                count: clockwise? rootGoalIdx: rotatingItems.length - rootGoalIdx,
                clockwise,
              })
            }
            afterOnClick?.(curItem);
          }}
        >
          {renderItem(curItem)}
        </div>
      );
    });
  }

  const leftRotate = useDebounce(() => {
    onClickLeftArrow();
    setSteps({
      count: 1,
      clockwise: true,
    });
    afterOnClick?.(rotatingItems[1]);
  }, { delay: 100, deps: [] });

  const rightRotate = useDebounce(() => {
    onClickRightArrow();
    setSteps({
      count: 1,
      clockwise: false,
    });
    afterOnClick?.(rotatingItems[rotatingItems.length - 1]);
  }, { delay: 100, deps: [] });

  const renderArrows = () => {
    return (
        <div className="arrows_container">
          <button
            className="arrow_button"
            onClick={leftRotate}
          >
            <img
              className="arrow_icon"
              src={require('../../public/arrow_left.png')}
              alt="arrow_left"
            />
          </button>
          <button
            className="arrow_button"
            onClick={rightRotate}
          >
            <img
              className="arrow_icon"
              src={require('../../public/arrow_right.png')}
              alt="arrow_right"
            />
          </button>
        </div>
    )
  }

  return (
    <div style={{
        '--total': total,
        perspective,
      perspectiveOrigin,
      height: '100vh',
      transformStyle: 'preserve-3d',
      pointerEvents: 'none',

        '--transition-time': `${transitionTime}ms`,
        '--item-width': typeof width === 'number' ? `${width}px` : 'auto',
        '--item-height': typeof height === 'number' ? `${height}px` : 'auto',
      }}
    >
      {renderArrows()}
      {renderItems()}
    </div>
  );
};
