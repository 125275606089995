import React, { useEffect, useState } from "react";
import { Intro1Component } from "../Intro1/Intro1Component";
import { Intro2Component } from "../Intro2/Intro2Component";
import { Intro3Component } from "../Intro3/Intro3Component";
import { Intro4Component } from "../Intro4/Intro4Component";
import { Intro5DiyaComponent } from "../Intro5Diya/Intro5DiyaComponent";
import { NavButtonsComponent } from "../Common/NavButtonsComponent";
import './ScrollSnapComponent.scss';
import { useNavigate } from "react-router-dom";
import { useRouter } from "../../../hooks/useRouter/useRouter";


export const ScrollSnapComponent = () => {
  const [current, setCurrent] = useState(0)
  const [diyaOn, setDiya] = useState(false);

  let preIndex = 0;
  let currentIndex = 0;
  let elementIndices = {};
  useEffect(() => {
    const container = document.querySelector('.container');
    const elements = document.querySelectorAll('.container > *');
    // console.log('elements', elements)
    const observer = new IntersectionObserver(function(entries, observer) {
      // find the entry with the largest intersection ratio
      let activated = entries.reduce(function (max, entry) {
        return (entry.intersectionRatio > max.intersectionRatio) ? entry : max;
      });
      if (activated.intersectionRatio > 0) {
        // @ts-ignore
        currentIndex = elementIndices[activated.target.getAttribute("id")];
        // console.log('currentIndex-->', currentIndex, 'preIndex-->',preIndex)
        if (preIndex !== currentIndex) {
          // if (currentIndex < 4) {
          //   setDiya(false);
          // }
          if (currentIndex === 4 && preIndex > currentIndex) {
            setDiya(false);
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
          preIndex = currentIndex;
        }
        setCurrent(currentIndex);
      }
    }, {
      root: container, threshold: 0.5
    });
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].getAttribute("id") !== null) {
        // @ts-ignore
        elementIndices[elements[i].getAttribute("id")] = i;
        observer.observe(elements[i]);
      }
    }
    return () => {
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].getAttribute("id") !== null) {
          // @ts-ignore
          elementIndices[elements[i].getAttribute("id")] = i;
          observer.unobserve(elements[i]);
        }
      }
    }
  }, [diyaOn])

  const navigate = useNavigate();

  const updateView = (position: number) => {
    console.log('position', position);
    if (position === 5) {
      navigate('/recipe')
    } else {
      document.getElementById(`page_${position > 8 ? 0 : position}`)?.scrollIntoView();
    }
  }

  const goToNextPage = () => {
    updateView(current + 1);
  }

  const goToPrePage = () => {
    // if (current === 4) {
    //   setDiya(false);
    // }
    updateView(current - 1);
  }

  const { query, location, setSearchParams} = useRouter();
  useEffect(() => {
    if (query.back === 'name') {
      setDiya(true);
      setTimeout(()=> {
        document.getElementById(`page_5`)?.scrollIntoView({
          behavior: 'auto'
        });
        setSearchParams('');
      }, 1000)
    }
  }, [query, location])

  return (
    <div className={'container'}>
      <Intro1Component />
      <Intro2Component />
      <Intro3Component />
      <Intro4Component />
      <Intro5DiyaComponent
        onLightUp={() => {
          setDiya(true);
          // setTimeout(()=> {
          //   goToNextPage();
          // })
        }}
        diyaOn={diyaOn}
        onLighted={() => {
          goToNextPage();
        }}
      />

      <NavButtonsComponent
        onNext={goToNextPage}
        onPre={goToPrePage}
        showPre={current > 0}
        showNext={(current > 0 && current < 6
          && (current !== 4))}
      />
    </div>
  );
}
