import React, { useEffect, useRef, useState } from "react";
import './NoPageComponent.scss';
import { useRouter } from "../../hooks/useRouter/useRouter";
import { useInView } from "framer-motion";
import { page_margin } from "../../features/storyIntroduce/Common/Common";
import { statisticsService } from "../../services/StatisticsService";
import {
  EVENT_404_PAGE_ENTERED,
  EVENT_404_PAGE_LEAVED,
} from "../../features/storyIntroduce/Common/Consts";

export const NoPageComponent = () => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef(null)
  const isInView = useInView(ref, {
    margin: page_margin
  })
  useEffect(() => {
    if (isInView) {
      setMounted(true);
      statisticsService.postEvents(EVENT_404_PAGE_ENTERED);
    } else {
      if (mounted) {
        statisticsService.postEvents(EVENT_404_PAGE_LEAVED);
      }
    }
  }, [isInView])

  const { navigate } = useRouter();
  return (
    <div ref={ref} className="NoPageComponent" onClick={() => {
      navigate('/');
    }}>
      <div>
        <div className="text_404">
         404
        </div>

        <div className="text">
          Page not found
        </div>
      </div>
    </div>
  )
}
