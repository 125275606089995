import React, { useEffect } from "react";
import { useRouter } from "../../../../hooks/useRouter/useRouter";

export const TransitionComponent = () => {

  const { query, location, navigate } = useRouter();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [query, location])
  return (
    <div className="send-email-component">
      <div className="bottom_container">
        <div className="mt100">
          <img
            alt=""
            width={186}
            src={require('../../../../public/diya_on.png')}
          />
        </div>
        <div className="mt30">
      <span className="text_sent">
        Great! Your recipe book has been successfully generated!
      </span>
        </div>
      </div>
    </div>
  )
}
