import './Intro3Component.scss';
import React, { useEffect, useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import { TypingRowsComponent } from "../Common/TypingRowsComponent";
import { statisticsService } from "../../../services/StatisticsService";
import { page_margin } from "../Common/Common";
import { EVENT_PAGE3_ENTERED, EVENT_PAGE3_LEAVED } from "../Common/Consts";

export const Intro3Component = () => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef(null)
  const isInView = useInView(ref, {
    margin: page_margin
  })
  useEffect(() => {
    if (isInView) {
      setMounted(true);
      statisticsService.postEvents(EVENT_PAGE3_ENTERED);
    } else {
      if (mounted) {
        statisticsService.postEvents(EVENT_PAGE3_LEAVED);
      }
    }
  }, [isInView])

  return (
    <motion.div id="page_2" className="pageContainer intro3-component" ref={ref}>
      <div className="background" />
      {
        mounted &&
        <>
          <div className="text_container_white">
            <div className="theme_bar"></div>
            <div className="mt20">
              <TypingRowsComponent
                rows={[
                  "On Diwali day,",
                  "families gather to",
                  "share laughter,",
                  "tokens of love, and",
                  "delicious food."
                ]}
                className="text_bold"
              />
            </div>
            <div className="mt30">
              <div>
                <TypingRowsComponent
                  rows={[
                    "To celebrate, our",
                    "dietitian has selected 3",
                    "nutritious South Asian",
                    "recipes for you."
                  ]}
                  delay={4500}
                />
              </div>
            </div>
          </div>
        </>
      }
    </motion.div>
  )
}
