
import axios from 'axios';
const Domain = process.env.REACT_APP_STATISTICS_DOMAIN;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

export interface UserEmail {
  name: string;
  email: string;
}
export class SendEmailService {
  protected endPoints = {
    sendEmail: `${Domain}/Prod/pdf-email`,
  };


  requestSendEmail = (data: UserEmail) => {
    return axios.request({
      url: this.endPoints.sendEmail,
      method: "POST",
      headers: {
        'x-api-key': X_API_KEY,
      },
      data,
    });
  }

}

export const sendEmailService = new SendEmailService();
