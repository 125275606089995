import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import { statisticsService } from "../../../../services/StatisticsService";
import {
  CLICK_RESEND_EMAIL_ON_EMAIL_PAGE,
  EVENT_EMAIL_PAGE_ENTERED,
  EVENT_EMAIL_PAGE_LEAVED
} from "../../Common/Consts";
import { page_margin } from "../../Common/Common";

export interface EmailSentComponentProps {
  email: string;
  onPre: () => void;
}
export const EmailSentComponentFull = (
  {
    email,
    onPre,
  }: EmailSentComponentProps) => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef(null)
  const isInView = useInView(ref, {
    margin: page_margin
  })
  useEffect(() => {
    if (isInView) {
      setMounted(true);
      statisticsService.postEvents(EVENT_EMAIL_PAGE_ENTERED);
    } else {
      if (mounted) {
        statisticsService.postEvents(EVENT_EMAIL_PAGE_LEAVED);
      }
    }
  }, [isInView])

  return (
    <div id="page_9"  className="pageContainer" ref={ref}>
      <motion.div className="send-email-component">
        <div className="bottom_container">
          <div className="mt20 mb20">
            <img
              alt=""
              width={200}
              src={require('../../../../public/magazine/Diwali-cover.jpg')}
            />
          </div>
          <div>
          <span className="text_sent">
            {`Your recipe book has been sent to your email ${email}`}
          </span>
          </div>

          <div className="flex jc-c">
            <Button
              className="resend_container mt30"
              onClick={() => {
                statisticsService.postEvents(CLICK_RESEND_EMAIL_ON_EMAIL_PAGE);
                // navigate('/email')
                onPre();
              }}
            >
          <span className="resend_text">
            Resend Email
          </span>
            </Button>
          </div>
        </div>
      </motion.div>
    </div>
  )
}
