import React, { useEffect, useRef, useState } from "react";
// @ts-ignore
import _ from "lodash";
import './Intro6RecipeComponent.scss';
import { page_margin, RecipesList, RecipeType } from "../Common/Common";
import { RotatingComponent } from "../../../util/RotatingComponent/RotatingComponent";
import { animate, motion, motionValue, useInView } from "framer-motion";
import { statisticsService } from "../../../services/StatisticsService";
import {
  CLICK_LEFT_ARROW_ON_RECIPE_PAGE,
  CLICK_RIGHT_ARROW_ON_RECIPE_PAGE,
  CLICK_RECIPE_ITEM_ON_RECIPE_PAGE,
  CLICK_SEE_RECIPE_ON_RECIPE_PAGE,
  EVENT_RECIPE_PAGE_ENTERED,
  EVENT_RECIPE_PAGE_LEAVED,
  RECIPE_NAME,
} from "../Common/Consts";
import { useDebounce } from "../../../util/RotatingComponent/useDebounce";
import { NewRecipeDrawerComponent } from "../../recipe/NewRecipeDrawerComponent/NewRecipeDrawerComponent";
import { useRouter } from "../../../hooks/useRouter/useRouter";
import { NavButtonsComponent } from "../Common/NavButtonsComponent";

export interface CarouselItemType {
  id: string;
  value: any;
}
export const Intro6RecipeComponent = () => {
    const [mounted, setMounted] = useState(false);
    const ref = useRef(null)
    const isInView = useInView(ref, {
        margin: page_margin
    })
    useEffect(() => {
      if (isInView) {
        setMounted(true);
        statisticsService.postEvents(EVENT_RECIPE_PAGE_ENTERED);
      } else {
        if (mounted) {
          statisticsService.postEvents(EVENT_RECIPE_PAGE_LEAVED);
        }
      }
    }, [isInView])

  const [ activeId, setId ] = useState<string>('recipe_1');
  const [ pop, showPop, ] = useState<boolean>(false);

  const handItemClicked = (id: string) => {
    const activeItem = RecipesList.find(item => item.id === id);
    statisticsService.postEvents(CLICK_RECIPE_ITEM_ON_RECIPE_PAGE, {
      [RECIPE_NAME]: activeItem?.title
    });
    if (activeId === id) {
      showPop(true);
    } else {
      setId(id);
    }
  }

  const checkDetails = useDebounce((item: boolean) => {
    const activeItem = RecipesList.find(item => item.id === activeId);
    statisticsService.postEvents(CLICK_SEE_RECIPE_ON_RECIPE_PAGE, {
      [RECIPE_NAME]: activeItem?.title
    });
  }, { delay: 100, deps: [] });

  const items: CarouselItemType[] = RecipesList.map(item => {
    return {
      id: item.id,
      src: item.img,
      value: item.id,
    }
  });

  const y1 = motionValue(0)
  animate(y1, [0, 1, 3, 1, -1, -3, -1, 0], {
    duration: 2,
    repeat: 100000,
  })
  const opacity = motionValue(0.5)
  animate(opacity, [0.8, 1, 0.8], {
    duration: 2,
    repeat: 100000,
  })

  const renderPop = () => {
    const activeItem = RecipesList.find(item => item.id === activeId);
    return (
      <div className="carousel_container">
        <NewRecipeDrawerComponent
          recipe={activeItem as RecipeType}
          onClick={checkDetails}
          open={pop}
          setOpen={(show) => showPop(show)}
        />

        <div className="mt30">

          <div className="title">
            {activeItem?.title}
          </div>
        </div>
        <motion.div className="mt10"
                    style={{  x: 0, y: y1, opacity }}
        >
          <img
            src={ require("../../../public/icon_next.png") }
            alt="icon_next"
            width={ 43 }
            height={ 35 }
            onClick={goToNextPage}
          />
        </motion.div>

      </div>
    )
  }
  const activeSize: number = 210
  const inactiveSize: number = 210;

  const goToNextPage = () => {
    navigate('/name')
  }
  const goToPrePage = () => {
    navigate('/');
  }

  const { query, location, navigate } = useRouter();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [query, location])
  return (
    <motion.div id="page_5"  className="pageContainer recipe-component" ref={ref}
                onPan={(e, pointInfo) => {
                  console.log('e',e)
                  console.log('pointInfo',pointInfo)
                  if (Math.abs(pointInfo.delta.x) < Math.abs(pointInfo.delta.y) && pointInfo.delta.y < -5) {
                    goToNextPage()
                  }
                  if ( Math.abs(pointInfo.delta.x) < Math.abs(pointInfo.delta.y) && pointInfo.delta.y > 5) {
                    goToPrePage()
                  }
                  window.scrollTo(0, 0);
                }}
    >
      <div className="background" onClick={() => showPop(false)}/>
        {
          mounted &&
            <>
                <RotatingComponent
                    width={activeSize}
                    height={activeSize}
                    radius={200}
                    list={items}
                    renderItem={(item)=> {
                      const active = activeId === item.id;
                        return (
                          <div
                              className="flex-center-center"
                              style={{
                                  width: activeSize,
                                  height: activeSize,
                              }}
                             >
                            <img
                                key={`${ item.id }`}
                                src={item.src}
                                alt={`img_${item.id}`}
                                width={active? activeSize : inactiveSize}
                            />
                          </div>
                        );
                    }}
                    afterOnClick={item => {
                      handItemClicked(item?.id);
                    }}
                    onClickLeftArrow={() => {
                      statisticsService.postEvents(CLICK_LEFT_ARROW_ON_RECIPE_PAGE);
                    }}
                    onClickRightArrow={() => {
                      statisticsService.postEvents(CLICK_RIGHT_ARROW_ON_RECIPE_PAGE);
                    }}
                />
                {renderPop()}
            </>
        }
      <NavButtonsComponent
        onNext={goToNextPage}
        onPre={goToPrePage}
        showPre={true}
        showNext={false}
      />
    </motion.div>
  )
}
