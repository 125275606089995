import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, message, Spin } from "antd";
import '../Intro7SendEmailComponent.scss';
import { useInView } from "framer-motion";
import { statisticsService } from "../../../../services/StatisticsService";
import { sendEmailService, UserEmail } from "../../../../services/SendEmailService";
import {
  CLICK_SEND_EMAIL_ON_EMAIL_PAGE,
  EVENT_EMAIL_PAGE_ENTERED,
  EVENT_EMAIL_PAGE_LEAVED,
  EVENT_EMAIL_SEND_FAILED,
  EVENT_EMAIL_SEND_SUCCESS
} from "../../Common/Consts";
import { page_margin } from "../../Common/Common";

export type STATUS = 'name' | 'transition' | 'carousel' | 'email' | 'sent';

export interface Intro7SendEmailComponentProps {
  initStatus: STATUS;
  name?: string;
  updateName?: (value: string) => void;
  email?: string;
  updateEmail?: (value: string) => void;
  onTransition?: () => void;
  onSent?: () => void;
}
export const EnterEmailComponentFull = (
  {
    initStatus,
    name = '',
    email = '',
    updateEmail,
    onSent,
  }: Intro7SendEmailComponentProps) => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef(null)
  const isInView = useInView(ref, {
    margin: page_margin
  })
  useEffect(() => {
    if (isInView) {
      setMounted(true);
      statisticsService.postEvents(EVENT_EMAIL_PAGE_ENTERED);
    } else {
      if (mounted) {
        statisticsService.postEvents(EVENT_EMAIL_PAGE_LEAVED);
      }
    }
  }, [isInView])

  const [ status, setStatus] = useState<STATUS>(initStatus);

  const [ valueEmail, setEmail] = useState<UserEmail>({name, email});
  const [loading, setLoading] = useState(false);

  const onSendEmailClicked = (values: UserEmail) => {
    setLoading(true);
    setEmail(values);
    updateEmail?.(values.email);
    statisticsService.postEvents(CLICK_SEND_EMAIL_ON_EMAIL_PAGE);
    sendEmailService.requestSendEmail({
      name,
      email: values.email,
    }).then(res => {
      setLoading(false);
      if (res.status === 200) {
        statisticsService.postEvents(EVENT_EMAIL_SEND_SUCCESS)
        // navigate('/sent');
        onSent?.();
      } else {
        statisticsService.postEvents(EVENT_EMAIL_SEND_FAILED, {
          reason: res
        })
        message.error('Failed to send email!')
      }
      console.log('requestSendEmail res ', res)
    }).catch(reason => {
      setLoading(false);
      statisticsService.postEvents(EVENT_EMAIL_SEND_FAILED, {
        reason
      })
      message.error('Failed to send email!')
      console.log('requestSendEmail reason ', reason)
    })
  };


  const renderActions = () => {
    switch (status) {
      case 'email':
        return (
          <div className="send-email-component">
            <Form id='form_send_email'
                  className="bottom_container"
                  onFinish={onSendEmailClicked}
                  initialValues={valueEmail}
            >
              <div className="mt20 mb20">
                <img
                  alt=""
                  width={200}
                  src={require('../../../../public/magazine/Diwali-cover.jpg')}
                />
              </div>
              <div>
              <span className="text_sent">
                Enter your email address below:
              </span>
              </div>
              <div className="mt30">
                <Form.Item
                  label=""
                  name="email"
                  rules={[{ required: true, message: 'Please input your email!' }]}
                >
                  <Input
                    type="email"
                    placeholder={'Enter email address'}
                  />
                </Form.Item>
              </div>
              <Button className="cta_container mt10"
                      htmlType="submit"
              >
      <span className="cta_text">
        Send Email
      </span>
              </Button>
            </Form>
          </div>
        )
    }
    return <div/>
  }

  return (
    <div id="page_8"  className="pageContainer" ref={ref}>
      <Spin spinning={loading}>
        {renderActions()}
      </Spin>
    </div>
  )
}
