import { Button } from "antd";
import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useRouter } from "../../../../hooks/useRouter/useRouter";
import { NavButtonsComponent } from "../../Common/NavButtonsComponent";
import { statisticsService } from "../../../../services/StatisticsService";
import { CLICK_RESEND_EMAIL_ON_EMAIL_PAGE } from "../../Common/Consts";

export interface EmailSentComponentProps {
  email: string;
}
export const EmailSentComponent = (
  {
    email,
  }: EmailSentComponentProps) => {

  const goToPrePage = () => {
    navigate('/email');
  }

  const { query, location, navigate } = useRouter();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [query, location])
  return (
    <motion.div className="send-email-component"
                onPanEnd={(e, pointInfo) => {
                  if (Math.abs(pointInfo.delta.x) < Math.abs(pointInfo.delta.y) && pointInfo.delta.y > 5) {
                    goToPrePage();
                  }
                }}
    >
      <div className="bottom_container">
        <div className="mt20 mb20">
          <img
            alt=""
            width={200}
            src={require('../../../../public/magazine/Diwali-cover.jpg')}
          />
        </div>
        <div>
          <span className="text_sent">
            {`Your recipe book has been sent to to your email ${email}`}
          </span>
        </div>

        <div className="flex jc-c">
          <Button
            className="resend_container mt30"
            onClick={() => {
              statisticsService.postEvents(CLICK_RESEND_EMAIL_ON_EMAIL_PAGE);
              navigate('/email')
            }}
          >
          <span className="resend_text">
            Resend Email
          </span>
          </Button>
        </div>
        <NavButtonsComponent
          onPre={goToPrePage}
          showPre={true}
          showNext={false}
        />
      </div>
    </motion.div>
  )
}
