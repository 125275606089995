import React, { useEffect, useRef, useState } from 'react';
import './Intro1Component.scss';
import { motion, useInView } from "framer-motion";
import { statisticsService } from '../../../services/StatisticsService';
import { page_margin } from "../Common/Common";
import { EVENT_PAGE1_ENTERED, EVENT_PAGE1_LEAVED } from '../Common/Consts'

export const Intro1Component = () => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef(null)
  const isInView = useInView(ref, {
    margin: page_margin
  })
  useEffect(() => {
    if (isInView) {
      setMounted(true);
      statisticsService.postEvents(EVENT_PAGE1_ENTERED);
    } else {
      if (mounted) {
        statisticsService.postEvents(EVENT_PAGE1_LEAVED);
      }
    }
  }, [isInView])
  return (
    <motion.div id="page_0" className="pageContainer intro1-component" ref={ref}>
      <div className="background" />
      <div className="text_container">
        <span className="text_bold">
          {'In ancient India, there was a beautiful belief...'}
        </span>
      </div>

      <div className="bottom_img mb50">
        <img
          src={require('../../../public/page1_gesture.gif')}
          alt="page1_gesture"
          width={120}
          style={{ opacity: 0.8 }}
        />
      </div>
      <div className="bottom_btn mb50">
        <span className="text_small">
          Scroll to see the content
        </span>
      </div>
    </motion.div>
  )
}
