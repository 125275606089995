import React, { useEffect, useState } from "react";
import { Intro1Component } from "../Intro1/Intro1Component";
import { Intro2Component } from "../Intro2/Intro2Component";
import { Intro3Component } from "../Intro3/Intro3Component";
import { Intro4Component } from "../Intro4/Intro4Component";
import { Intro5DiyaComponentFull } from "../Intro5Diya/Intro5DiyaComponentFull";
import { NavButtonsComponent } from "../Common/NavButtonsComponent";
import './ScrollSnapComponent.scss';
import { Intro6RecipeComponentFull } from "../Intro6Recipe/Intro6RecipeComponentFull";
import { EnterNameComponentFull } from "../Intro7SendEmail/EnterNameComponent/EnterNameComponentFull";
import { EnterEmailComponentFull } from "../Intro7SendEmail/EnterEmailComponent/EnterEmailComponentFull";
import { CarouselComponentFull } from "../Intro7SendEmail/CarouselComponent/CarouselComponentFull";
import { EmailSentComponentFull } from "../Intro7SendEmail/EmailSentComponent/EmailSentComponentFull";


export const ScrollSnapComponentFull = () => {
  const [current, setCurrent] = useState(0)
  const [diyaOn, setDiya] = useState(false);
  const [ name, setName] = useState('');
  const [ email, setEmail] = useState('');
  const [ nameSubmitted, setNameSubmitted] = useState(false);
  const [ carousel, setCarousel] = useState(false);
  const [ emailSent, setEmailSent] = useState(false);

  let preIndex = 0;
  let currentIndex = 0;
  let elementIndices = {};
  useEffect(() => {
    const container = document.querySelector('.container');
    const elements = document.querySelectorAll('.container > *');
    // console.log('elements', elements)
    const observer = new IntersectionObserver(function(entries, observer) {
      // find the entry with the largest intersection ratio
      let activated = entries.reduce(function (max, entry) {
        return (entry.intersectionRatio > max.intersectionRatio) ? entry : max;
      });
      if (activated.intersectionRatio > 0) {
        // @ts-ignore
        currentIndex = elementIndices[activated.target.getAttribute("id")];
        console.log('currentIndex-->', currentIndex, 'preIndex-->',preIndex)
        if (preIndex !== currentIndex) {
          if (currentIndex < 4) {
            setDiya(false);
          }
          if (currentIndex === 4 && preIndex > currentIndex) {
            setDiya(false);
          }
          if (currentIndex === 6 && preIndex > currentIndex) {
            setName('');
            setNameSubmitted(false);
            setCarousel(false);
            setEmailSent(false);
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
          preIndex = currentIndex;
        }
        setCurrent(currentIndex);
      }
    }, {
      root: container, threshold: 0.5
    });
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].getAttribute("id") !== null) {
        // @ts-ignore
        elementIndices[elements[i].getAttribute("id")] = i;
        observer.observe(elements[i]);
      }
    }
    return () => {
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].getAttribute("id") !== null) {
          // @ts-ignore
          elementIndices[elements[i].getAttribute("id")] = i;
          observer.unobserve(elements[i]);
        }
      }
    }
  }, [diyaOn, nameSubmitted, carousel, emailSent])

  const updateView = (position: number) => {
    console.log('position', position);
    document.getElementById(`page_${position > 10 ? 0 : position}`)?.scrollIntoView();

  }

  const goToNextPage = () => {
    updateView(current + 1);
  }

  const goToPrePage = () => {
    // if (current === 4) {
    //   setDiya(false);
    // }
    updateView(current - 1);
  }

  return (
    <div className={'container'}>
      <Intro1Component />
      <Intro2Component />
      <Intro3Component />
      <Intro4Component />
      <Intro5DiyaComponentFull
        onLightUp={() => {
          setDiya(true);
          // setTimeout(()=> {
          //   goToNextPage();
          // })
        }}
        diyaOn={diyaOn}
        onLighted={() => {
          goToNextPage();
        }}
      />
      {
        diyaOn &&
        <Intro6RecipeComponentFull />
      }
      {
        diyaOn &&
        (
          <EnterNameComponentFull
            initStatus='name'
            name={name}
            updateName={(value) => {
              setName(value);
            }}
            nameOnChange={() => {
              setNameSubmitted(false);
            }}
            onTransition={() => {
              setNameSubmitted(true);
              setTimeout(()=> {
                goToNextPage();
              }, 200)
            }}
          />
        )
      }
      {
        diyaOn && nameSubmitted &&
        (
          <CarouselComponentFull
            name={name}
            onCarousel={ ()=> {
              setCarousel(true);
              setTimeout(()=> {
                goToNextPage();
              },200)
            }}
          />
        )
      }
      {
        diyaOn && nameSubmitted && carousel &&
        (
          <EnterEmailComponentFull
            initStatus='email'
            name={name}
            email={email}
            updateEmail={(value) => setEmail(value)}
            onSent={() => {
              setEmailSent(true);
              setTimeout(()=> {
                goToNextPage();
              }, 200)
            }}
          />
        )
      }

      {
        diyaOn && nameSubmitted && carousel && emailSent &&
        (
          <EmailSentComponentFull
            email={email}
            onPre={()=> {
              goToPrePage()
            }}
          />
        )

      }

      <NavButtonsComponent
        onNext={goToNextPage}
        onPre={goToPrePage}
        showPre={current > 0}
        showNext={(current > 0 && current < 6
          && (current !== 4) )}
      />
    </div>
  );
}
