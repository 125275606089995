import { Button } from "antd";
import React, { useEffect } from "react";
import { animate, motion, motionValue } from "framer-motion";
import { useRouter } from "../../../../hooks/useRouter/useRouter";
import { NavButtonsComponent } from "../../Common/NavButtonsComponent";
import { statisticsService } from "../../../../services/StatisticsService";
import { CLICK_SEND_TO_MY_EMAIL_ON_EMAIL_PAGE } from "../../Common/Consts";

export interface CarouselComponentProps {
  name: string;
}
export const CarouselComponent = (
  {
    name,
  }: CarouselComponentProps) => {

  const goToNextPage = () => {
    navigate('/email')
  }
  const goToPrePage = () => {
    navigate('/name');
  }

  const { query, location, navigate } = useRouter();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [query, location])

  const x = motionValue(0)
  animate(x, [0, -3, -10, -3, 3, 10, 3, 0], {
    duration: 2,
    repeat: 100000,
  })

  return (
    <motion.div className="send-email-component flex fd-c ai-c"
                onPanEnd={(e, pointInfo) => {
                  if (Math.abs(pointInfo.delta.x) < Math.abs(pointInfo.delta.y) && pointInfo.delta.y > 5) {
                   goToPrePage();
                  }
                }}
    >
      <ul className="carousel_container">
        <li className="carousel_item" key={2}>
          <div className="letter_text_container">
            <span className="letter_text">
            {
              `Dear ${name || 'Friend'},
I hope these delicious recipes bring some warmth to this chilly weather. Remember, healthy eating is anything but boring; it's an exploration of life's wonderful flavors.

Don't shy away from savoring your favorite foods – after all, life is meant to be enjoyed! Here's to a future filled with not only smiles, but also a tapestry of wonderful memories woven together with good health.

Rest assured, we're here to support you every step of the way to a happier and healthier life.

Warm regards,
Unified Care Team
`
            }
          </span>
          </div>
        </li>
        <li className="carousel_item" key={1}>
          <img
            alt=""
            width={200}
            src={require('../../../../public/magazine/Diwali-cover.jpg')}
          />
        </li>
        <li className="carousel_item" key={3}>
          <img
            alt=""
            width={340}
            height={220}
            src={require('../../../../public/magazine/Diwali-3.jpg')}
          />
        </li>
        <li className="carousel_item" key={4}>
          <img
            alt=""
            width={340}
            height={220}
            src={require('../../../../public/magazine/Diwali-4.jpg')}
          />
        </li>
        <li className="carousel_item" key={5}>
          <img
            alt=""
            width={340}
            height={220}
            src={require('../../../../public/magazine/Diwali-5.jpg')}
          />
        </li>
        <li className="carousel_item" key={6}>
          <img
            alt=""
            width={200}
            src={require('../../../../public/magazine/Diwali-backcover.jpg')}
          />
        </li>
      </ul>
      <div className="swipe_left_right_container">
        <motion.div
          className="mt10"
          style={{ x, opacity: 1 }}
        >
          <img
            alt=""
            width={80}
            src={require('../../../../public/carousel_icon.png')}
          />
        </motion.div>
      </div>
      <Button className="cta_container_outline mt10"
              onClick={() => {
                statisticsService.postEvents(CLICK_SEND_TO_MY_EMAIL_ON_EMAIL_PAGE);
                goToNextPage();
              }}
      >
       <span className="cta_text">
         Send Recipes To My Email
       </span>
      </Button>
      <NavButtonsComponent
        onPre={goToPrePage}
        showPre={true}
        showNext={false}
      />
    </motion.div>
  )
}
