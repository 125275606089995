import { animate, motion, motionValue } from "framer-motion";

export interface NavButtonsProps {
  onNext?: ()=> void;
  onPre?: ()=> void;
  showNext?: boolean;
  showPre?: boolean;
}
export const NavButtonsComponent = (
  {
    onNext,
    onPre,
    showNext = true,
    showPre=  true,
  }: NavButtonsProps) => {
  const y = motionValue(0)
  animate(y, [0, -1, -3, -1, 1, 3, 1, 0], {
    duration: 2,
    repeat: 100000,
  })
  const y1 = motionValue(0)
  animate(y1, [0, 1, 3, 1, -1, -3, -1, 0], {
    duration: 2,
    repeat: 100000,
  })
  const opacity = motionValue(0.5)
  animate(opacity, [0.8, 1, 0.8], {
    duration: 2,
    repeat: 100000,
  })

  return (
    <section>
      {
        showPre &&
        <motion.div className="top_btn"
                    style={{ x: -21.5, y, opacity }}
        >
          <img
            src={ require("../../../public/icon_pre.png") }
            alt="icon_pre"
            width={ 43 }
            height={ 35 }
            onClick={onPre}
          />
        </motion.div>
      }
      {
        showNext &&
        <motion.div className="bottom_btn"
                    style={{  x: -21.5, y: y1, opacity }}
        >
          <img
            src={ require("../../../public/icon_next.png") }
            alt="icon_next"
            width={ 43 }
            height={ 35 }
            onClick={onNext}
          />
        </motion.div>
      }
    </section>
  )
}
