import { useCallback } from 'react';

export const useDebounce = (callback, {
  delay = 500,
  deps = [],
}) => (
  useCallback((...args) => {
    const timer = setTimeout(() => callback(...args), delay);
    return () => clearTimeout(timer);
  }, [deps])
);
