import React, { useEffect, useState } from 'react';
import { TypingComponent, TypingComponentProps } from "./TypingComponent";
// @ts-ignore
import _ from 'lodash';

export interface TypingRowsComponentProps extends  TypingComponentProps{
  rows: string[];
}
export const TypingRowsComponent = (
  {
    rows,
    interval = 40,
    delay = 1000,
    className = 'text',
  }: TypingRowsComponentProps) => {
  const [calTexts, setTexts] = useState<TypingComponentProps[]>([]);

  useEffect(() => {
    const texts: TypingComponentProps[]  = [];
    rows.forEach((row, index) => {
      let itemDelay = delay;
      if (index > 0) {
        itemDelay = row.length * interval + (texts[index - 1].delay || 0);
      }
      texts.push({
        text: row,
        delay: itemDelay
      })
    });
    setTexts(texts);
  }, [])

  return (
    <>
      {
        calTexts.map((item, index) => {
          return (
            <span key={`${item.text}-${index}`}>
              <TypingComponent
                text={item.text}
                delay={item.delay}
                interval={interval}
                className={className}
              />
            </span>
          )
        })
      }
    </>
  )
}
