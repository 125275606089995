export interface Actions {
  nextPage?: (current: number)=> void;
  prePage?: (current: number)=> void;
}

/**
 * Constraint the page isInView
 * */
export const page_margin: string = "-100px 0px -100px 0px";

export interface Hide {
  hide?: boolean;
}

export interface RecipeType {
  id: string;
  title: string;
  description: string;
  img: any;
  displayImg?: any;
  downLoadImg?: any;
}

export const RecipesList : RecipeType[] = [
  {
    id: 'recipe_1',
    title: 'Chana Masala',
    description: 'A healthful dish that combines hearty chickpeas with aromatic spices for a delightful flavor boost.',
    img: require('../../../public/recipe_chana_masala.png'),
    displayImg: require('../../../public/raw/recipe_chana_masala_detail.png'),
    downLoadImg: require('../../../public/raw/recipe_chana_masala_detail_download.jpg'),
  },
  {
    id: 'recipe_2',
    title: 'Masala Chai',
    description: 'This wonderful brew includes ginger, adding a delightful warmth that will envelop your senses.',
    img: require('../../../public/recipe_masala_chai.png'),
    displayImg: require('../../../public/raw/recipe_masala_chai_detail.png'),
    downLoadImg: require('../../../public/raw/recipe_masala_chai_detail_download.jpg'),
  },
  {
    id: 'recipe_3',
    title: 'Dry Fruit Ladoo',
    description: 'Craving dried fruits? You\'re in for a delightful surprise.',
    img: require('../../../public/recipe_dry_fruit_ladoo.png'),
    displayImg: require('../../../public/raw/recipe_dry_fruit_ladoo_detail.png'),
    downLoadImg: require('../../../public/raw/recipe_dry_fruit_ladoo_detail_download.jpg'),
  },
]
