import { Button, Form, Input } from "antd";
import React, { useEffect } from "react";
import { UserEmail } from "../../../../services/SendEmailService";
import { animate, motion, motionValue } from "framer-motion";
import { useRouter } from "../../../../hooks/useRouter/useRouter";
import { NavButtonsComponent } from "../../Common/NavButtonsComponent";

export interface EnterNameComponentProps {
  onSubmit: (value: UserEmail) => void;
  value: UserEmail;
  onChange?: ()=> void;
}
export const EnterNameComponent = (
  {
    onSubmit,
    value,
    onChange,
  }: EnterNameComponentProps
) => {
  // const y = motionValue(500)
  // animate(y, [500, 0], {
  //   duration: 0.5,
  //   type: 'spring'
  // })

  const goToPrePage = () => {
    navigate('/recipe');
  }

  const { query, location, navigate } = useRouter();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [query, location])
  return (
    <motion.div className="send-email-component"
                onPanEnd={(e, pointInfo) => {
                  if (Math.abs(pointInfo.delta.x) < Math.abs(pointInfo.delta.y) && pointInfo.delta.y > 5) {
                    goToPrePage();
                  }
                }}
    >
      <Form id='form_name'
            className="bottom_container"
            onFinish={onSubmit}
            initialValues={value}
      >
        <div className="mt20 mb20">
          <img
            alt=""
            width={200}
            src={require('../../../../public/magazine/Diwali-cover.jpg')}
          />
        </div>

        <div>
              <span className="text_sent">
                Enter your name to generate recipe book.
              </span>
        </div>
        <div className="mt30">
          <Form.Item
            label=""
            name="name"
            rules={[{ required: true, message: 'Please input your name!' }]}
          >
            <Input
              type="text"
              placeholder={'Enter name/nickname'}
              onChange={onChange}
            />
          </Form.Item>
        </div>
        <Button className="cta_container mt10"
                htmlType="submit"
        >
      <span className="cta_text">
        Generate Recipe Book
      </span>
        </Button>
      </Form>
      <NavButtonsComponent
        onPre={goToPrePage}
        showPre={true}
        showNext={false}
      />
    </motion.div>
  )
}
