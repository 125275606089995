// @ts-ignore
import useSound from 'use-sound';
import './Intro5DiyaComponent.scss';
import React, { useEffect, useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import { statisticsService } from "../../../services/StatisticsService";
import { page_margin } from "../Common/Common";
import {
  EVENT_DIYA_PAGE_ENTERED,
  EVENT_DIYA_PAGELEAVED,
  CLICK_DIYA_ON_DIYA_PAGE,
} from "../Common/Consts";
import { useDebounce } from "../../../util/RotatingComponent/useDebounce";

export interface Intro4DiyaComponentProps {
  diyaOn?: boolean;
  onLightUp?: () => void;
  onLighted?: () => void;
}
export const Intro5DiyaComponentFull = (
  {
    diyaOn,
    onLightUp,
    onLighted,
  }: Intro4DiyaComponentProps) => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef(null)
  const isInView = useInView(ref, {
    margin: page_margin
  })
  useEffect(() => {
    if (isInView) {
      setMounted(true);
      statisticsService.postEvents(EVENT_DIYA_PAGE_ENTERED);
    } else {
      if (mounted) {
        statisticsService.postEvents(EVENT_DIYA_PAGELEAVED);
      }
    }
  }, [isInView])

  // Need to use absolute path
  const playId = 'intro4_audio';
  const effectDuration: number = 6000; // ms
  const [play, {stop}] = useSound('/lighting_candle.mp3', {
    id: playId,
    interrupt: true,
  })
  const onDiyaClicked = useDebounce(() => {
    play();
    onLightUp?.();
    setTimeout(()=> {
      stop();
      onLighted?.();
    }, effectDuration);
    statisticsService.postEvents(CLICK_DIYA_ON_DIYA_PAGE);
  }, { delay: 100, deps: [] });

  const renderDiaOff = () => {
    return (
      <div className="intro4_container">
        <div className="diaya_off_text_container">
          <span
            className="diaya_off_text"
          >
            Now, let’s light up the Diya for Diwali and start exploring nutritious recipes!
          </span>
        </div>
        <div className="reminder_text_container">
           <span className="text_reminder">
                Tap on the diya to light it
              </span>
        </div>

        <div className="intro5_click_area" onClick={onDiyaClicked}/>
      </div>
    )
  }

  const renderDiaOn = () => {
    return (
      <div className="intro5_container">
        <div className="diaya_off_text_container">
          <span
            className="diaya_on_text"
          >
            The Diya has been lit, symbolizing that light will eventually conquer darkness, and health will conquer disease!
          </span>
        </div>
      </div>
    )
  }

  return (
    <motion.div id="page_4" ref={ref}>
      <div className="pageContainer">
        {
          mounted &&
          <>
            {
              diyaOn?
                renderDiaOn()
                :
                renderDiaOff()
            }
          </>
        }
      </div>
    </motion.div>
  )
}
