import React, { useEffect, useState } from 'react';

export interface TypingComponentProps {
  text?: string;
  interval?: number; //100 ms
  delay?: number; // 1000 ms
  className?: string;
}
export const TypingComponent = (
  {
    text = '',
    interval = 100,
    delay = 1000,
    className = 'text',
  }:TypingComponentProps) => {
  const [ displayText, setDisplayText ] = useState<string>('');
  useEffect(()=> {
    console.log(text.length, text.length*interval)
    setTimeout(() => {
      appendingText();
    }, delay)
  }, []);
  let charIndex = 0;
  const appendingText = () => {
    if (charIndex <= text.length) {
      setDisplayText(text.substring(0, charIndex));
      charIndex++;
      setTimeout(appendingText, interval);
    }
  }
  return (
    <span className={className}>
      {displayText}
    </span>
  )
}
