import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Spin } from "antd";
import '../Intro7SendEmailComponent.scss';
import { useInView } from "framer-motion";
import { statisticsService } from "../../../../services/StatisticsService";
import { UserEmail } from "../../../../services/SendEmailService";
import {
  CLICK_GENERATE_RECIPE_BOOK_ON_EMAIL_PAGE,
  EVENT_NAME_PAGE_ENTERED,
  EVENT_NAME_PAGE_LEAVED,
} from "../../Common/Consts";
import { page_margin } from "../../Common/Common";
import { TransitionComponent } from "../TransitionComponent/TransitionComponent";

export type STATUS = 'name' | 'transition' | 'carousel' | 'email' | 'sent';

export interface Intro7SendEmailComponentProps {
  initStatus: STATUS;
  name?: string;
  nameOnChange?: () => void;
  updateName?: (value: string) => void;
  email?: string;
  updateEmail?: (value: string) => void;
  onTransition?: () => void;
  onSent?: () => void;
}
export const EnterNameComponentFull = (
  {
    initStatus,
    name = '',
    nameOnChange,
    updateName,
    email = '',
    onTransition,
  }: Intro7SendEmailComponentProps) => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef(null)
  const isInView = useInView(ref, {
    margin: page_margin
  })
  useEffect(() => {
    if (isInView) {
      setMounted(true);
      statisticsService.postEvents(EVENT_NAME_PAGE_ENTERED);
    } else {
      if (mounted) {
        statisticsService.postEvents(EVENT_NAME_PAGE_LEAVED);
        setStatus('name');
      }
    }
  }, [isInView])

  const [ status, setStatus] = useState<STATUS>(initStatus);

  const [ valueName, setName] = useState<UserEmail>({name, email});
  const [loading, setLoading] = useState(false);
  const TRANSITION_DURATION = 1000;
  const onGenerateBookClicked = (values: UserEmail) => {
    setName(values);
    updateName?.(values.name);
    statisticsService.postEvents(CLICK_GENERATE_RECIPE_BOOK_ON_EMAIL_PAGE);
    setStatus('transition');
    setTimeout(()=> {
      // navigate('/carousel');
      onTransition?.();
    }, TRANSITION_DURATION)
  };

  const renderActions = () => {
    switch (status) {
      case 'name':
        return (
          <div className="send-email-component">
            <Form id='form_name'
                  className="bottom_container"
                  onFinish={onGenerateBookClicked}
                  initialValues={valueName}
            >
              <div className="mt20 mb20">
                <img
                  alt=""
                  width={200}
                  src={require('../../../../public/magazine/Diwali-cover.jpg')}
                />
              </div>

              <div>
              <span className="text_sent">
                Enter your name to generate recipe book.
              </span>
              </div>
              <div className="mt30">
                <Form.Item
                  label=""
                  name="name"
                  rules={[{ required: true, message: 'Please input your name!' }]}
                >
                  <Input
                    type="text"
                    placeholder={'Enter name/nickname'}
                    onChange={nameOnChange}
                  />
                </Form.Item>
              </div>
              <Button className="cta_container mt10"
                      htmlType="submit"
              >
      <span className="cta_text">
        Generate Recipe Book
      </span>
              </Button>
            </Form>
          </div>
        )

      case 'transition':
        return (
          <TransitionComponent />
        )
    }
    return <div/>
  }

  return (
    <div id="page_6"  className="pageContainer" ref={ref}>
      <Spin spinning={loading}>
        {renderActions()}
      </Spin>
    </div>
  )
}
