import './Intro4Component.scss';
import React, { useEffect, useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import { statisticsService } from "../../../services/StatisticsService";
import { page_margin } from "../Common/Common";
import { EVENT_PAGE4_ENTERED, EVENT_PAGE4_LEAVED } from "../Common/Consts";

export const Intro4Component = () => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef(null)
  const isInView = useInView(ref, {
    margin: page_margin
  })
  useEffect(() => {
    if (isInView) {
      setMounted(true);
      statisticsService.postEvents(EVENT_PAGE4_ENTERED);
    } else {
      if (mounted) {
        statisticsService.postEvents(EVENT_PAGE4_LEAVED);
      }
    }
  }, [isInView])

  return (
    <motion.div id="page_3" className="pageContainer intro4-component" ref={ref}>
      <div className="background" />
      {
        mounted &&
        <>
          <div className="text_container_white">
            <div className="theme_bar"></div>
            <div className="mt20">
              <span className="text_bold">
                {'Remember, healthy food can and should be delicious!'}
              </span>
            </div>
          </div>
        </>
      }
    </motion.div>
  )
}
