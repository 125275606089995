import { Button, Drawer } from "antd";
import React, { useEffect, useRef, useState } from "react";
import './NewRecipeDrawerComponent.scss';
import { statisticsService } from "../../../services/StatisticsService";
import {
  CLICK_CLOSE_ON_RECIPE_DETAIL_PAGE,
  CLICK_SAVE_ON_RECIPE_DETAIL_PAGE,
  EVENT_RECIPE_DETAIL_PAGE_ENTERED,
} from "../../storyIntroduce/Common/Consts";
import { RecipeType } from "../../storyIntroduce/Common/Common";

export interface RecipeDrawerComponentProps {
  recipe: RecipeType;
  onClick?: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const NewRecipeDrawerComponent = (
  {
    recipe,
    onClick,
    open,
    setOpen,
  }: RecipeDrawerComponentProps) => {

  useEffect(() => {
    if (open) {
      statisticsService.postEvents(EVENT_RECIPE_DETAIL_PAGE_ENTERED, {

      })
    }
  }, [open]);
  const handleClose = () => {
    setOpen(false)
    statisticsService.postEvents(CLICK_CLOSE_ON_RECIPE_DETAIL_PAGE);

  }

  const handleSave = async () => {
    statisticsService.postEvents(CLICK_SAVE_ON_RECIPE_DETAIL_PAGE);

    const fileName = `UC_${recipe.title.replace(' ', '')}.jpg`;
    if (navigator.share) {
      try {
        fetch(recipe.downLoadImg)
          .then((response) => {
            return response.blob()
          })
          .then(blob => {
            const file = new File([blob], fileName, {type: 'image/jpeg'});
            const filesArray = [file];
            navigator.share({
              title: recipe.title,
              text: recipe.description,
              // url: recipe.downLoadImg,
              files: filesArray,
            });
          })
      } catch (error) {
        console.error('Error sharing:', error);
        // @ts-ignore
        const link = document.createElement('a');
        link.href = recipe.downLoadImg;
        link.download = fileName;
        link.click();
      }
    } else {
      console.log('Web Share API is not supported.');
      // @ts-ignore
      const link = document.createElement('a');
      link.href = recipe.downLoadImg;
      link.download = fileName;
      link.click();
    }
  }

  const renderBackButton = () => {
    return (
      <div className="back_button_container">
        <Button className="back_button" type="default"
                onClick={handleClose}
        >
          <img
            width={10}
            src={require('../../../public/arrow_left.png')}
          />
          <span className="back_button_text">
          Back
        </span>
        </Button>
      </div>
    )
  }

  const renderSaveButton = () => {
    return (
      <Button className="save_button" type="default"
              onClick={handleSave}
      >
        <span className="button_text">
          Save photo recipe
        </span>
      </Button>
    )
  }

  const imageRef = useRef(null);

  return (
    <div className="NewRecipeDrawerComponent">
      <Button className="seeRecipeButton" type="default" onClick={() => {
        onClick?.();
        setOpen(true);
      }}>
        See recipe
      </Button>
      <Drawer
        placement="bottom"
        height="100%"
        onClose={handleClose}
        open={open}
        className="recipeDrawerContainer"
        maskStyle={{ background: 'transparent' }}
        closeIcon={(
          <div className="flex fd-r ai-c">
            <img
              className="arrow_icon"
              src={require('../../../public/arrow_left.png')}
              alt="arrow_left_back"
            />
            <span className="back_text">
              Back
            </span>
          </div>
        )}
      >
        {renderBackButton()}
        <img
          className="image"
          ref={imageRef}
          src={recipe.displayImg}
          alt="recipe_detail"
        >
        </img>
        {renderSaveButton()}
      </Drawer>
    </div>
  )
}
