export const checkOS = () => {
  const userAgent = navigator.userAgent;
  if (/iPhone|iPad|iPod/i.test(userAgent)) return 'iOS'
  if (/Android/i.test(userAgent)) return 'Android';
  return 'unknown';
}
export const MonitorSoftKeyboard = (callback: (show: boolean) => void) => {
  const originalHeight = document.documentElement.clientHeight || document.body.clientHeight;
  window.addEventListener('resize', () => {
    if (checkOS() === 'Android') {
      const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
      if (Math.abs(resizeHeight - originalHeight) < 10) {
        callback(false);
      } else {
        callback(true);
      }
    }
  })
}
