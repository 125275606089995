import './Intro2Component.scss';
import React, { useEffect, useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import { TypingRowsComponent } from "../Common/TypingRowsComponent";
import { statisticsService } from "../../../services/StatisticsService";
import { page_margin } from "../Common/Common";
import { EVENT_PAGE2_ENTERED, EVENT_PAGE2_LEAVED } from '../Common/Consts'

export const Intro2Component = () => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef(null)
  const isInView = useInView(ref, {
    margin: page_margin
  })
  useEffect(() => {
    if (isInView) {
      setMounted(true);
      statisticsService.postEvents(EVENT_PAGE2_ENTERED);
    } else {
      if (mounted) {
        statisticsService.postEvents(EVENT_PAGE2_LEAVED);
      }
    }
  }, [isInView])

  return (
    <motion.div id="page_1" className="pageContainer intro2-component" ref={ref}>
      <div className="background" />
      {
        mounted &&
        <>
          <div className="text_container_top">
            <div className="theme_bar"></div>
            <div className="mt20">
              <TypingRowsComponent
                rows={[
                  "“No matter how",
                  "powerful the dark",
                  "-ness may be, light",
                  "will eventually",
                  "conquer it.”"
                ]}
                className="text_bold"
              />
            </div>
            <div className="mt20 mb20">
              <TypingRowsComponent
                rows={[
                  "The symbol of this",
                  "belief is Diwali."
                ]}
                delay={5000}
              />
            </div>
          </div>
          <img
            className="bottom_right_fade_in"
            src={require('../../../public/page2_lights.gif')}
            alt="page2_lights"
            width={ 200 }
          />
        </>
      }
    </motion.div>
  )
}
